import React, { useCallback, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import styles from './Homework.module.sass'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import PinRed from '../../ListView/pin_red.png';
import PinGrey from '../../ListView/pin_grey.png'
import useUserTeams from '../../../../hooks/useUserTeam';
import { useVideoCategoriesByAction } from '../../../../hooks/useVideoCategoriesByAction';
import { CloseOutlined } from '@ant-design/icons';
import { deleteFavorite, addFavorite, addHomework, removeHomework } from '../../../../Redux/listViewReducer';
import bellIcon from './bell.png'

import {
  createFavoriteJourney,
  deleteFavoriteBySubTitle,
  createHomework,
  deleteHomework, 
  notifyHomework} from '../../../../api/api';
import { ModalAssignment } from '../../../ProfilePage/ModalWindowPopap/ModalAssignment';
import { message } from 'antd';
import { ModalHomeworkTag } from '../../../ProfilePage/ModalWindowPopap/ModalHomeworkTag';

const Homework = ({ currentTeam }) => {
  const { data } = useSelector(state => state.listViewReducer)
  const favoriteData = useVideoCategoriesByAction('SET_MY_FAVORITE_CATEGORY_DATA').find((item) => item.actionType === 'SET_MY_FAVORITE_SUBCATEGORY_DATA').children
  const homework = data?.find(item => item.actionType === 'SET_MY_HOMEWORK_DATA').children.find((item) => item.actionType === `cat-${currentTeam.id}`);
  const { manageTeams, currentTeamStats } = useUserTeams()
  const playerID = useSelector((state) => state.playerDataReducer.playerData.id);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null)
  const [isNotified, setIsNotified] = useState(false)
  const [isNotifying, setIsNotifying] = useState(false)
  const [selectedHomework, setSelectedHomework] = useState(null)
  const [selectedTags, setSelectedTags] = useState([])

  
  const checkAccess = () =>
    currentTeamStats.managers.some((element) => element.childId === playerID);
  const isPlayerManager = currentTeamStats.players.some(
    (element) => element.childId === playerID && element.role === 'player_manager',
  );
  const isManager = checkAccess();
  
  const homeworkData = useMemo(() => {
    const allHomework = data?.find(item => item.actionType === 'SET_MY_HOMEWORK_DATA').children.map((item) => item.children[0].children).flat();
    
    // Use reduce to filter out duplicate entries based on title and subTitle
    const uniqueHomework = allHomework.reduce((acc, current) => {
      const duplicate = acc.find(item => item?.title === current?.title && item?.subTitle === current?.subTitle);
      if (!duplicate) {
        return [...acc, current];
      }
      return acc;
    }, []);
  
    return uniqueHomework;
  }, [data]);

  
  const checkFavorite = useCallback(
    (_item) => {
      const favoriteCheck = favoriteData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle
        } else {
          return item.title === _item.title
        }
      })

      return favoriteCheck
    },
    [favoriteData],
  )

  const checkHomeworkWithFavorite = useCallback(
    (_item) => {

      const homeworkCheck = homeworkData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle
        } else {
          return item.title === _item.title
        }
      })
  
      const favoriteCheck = favoriteData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle
        } else {
          return item.title === _item.title
        }
      })

      return manageTeams.length > 0 ? (homeworkCheck || favoriteCheck) : favoriteCheck
    },
    [favoriteData, homeworkData, manageTeams],
  )

  

  const handleFavoriteClick = useCallback(
    async (item) => {
      if (manageTeams.length > 0) {
        setSelectedItem(item)
        return
      } 
  
      if (manageTeams.length === 0) {
        if (checkFavorite(item)) {
          await deleteFavoriteBySubTitle(item).then((res) => {
            console.log(res.msg)
            dispatch(deleteFavorite(item))
          })
        } else {
          await createFavoriteJourney(item).then((res) => {
            dispatch(addFavorite(res.favorite))
          });
        }
      }
    },
    [checkFavorite, manageTeams, dispatch],
  )

  const checkHomeworkWithTeam = useCallback(
    (team, _item) => {
      const teamData = data?.find(item => item.actionType === 'SET_MY_HOMEWORK_DATA')
        ?.children.find(item => item.actionType === `cat-${team.id}`)
        ?.children[0].children;
  
      const homeworkCheck = teamData?.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle;
        } else {
          return item.title === _item.title;
        }
      });
  
      return homeworkCheck;
    },
    [data],
  );

  const onSubmit = useCallback(async (teams, addToFavorite) => {
    // Remove homework from teams that have been unchecked
    const teamsToRemoveHomework = manageTeams.filter(team => 
      checkHomeworkWithTeam(team, selectedItem) && !teams.some(selectedTeam => selectedTeam.id === team.id)
    );

    for (const team of teamsToRemoveHomework) {
      await deleteHomework({...selectedItem, team }).then((res) => {
        console.info(res.msg);
        dispatch(removeHomework(selectedItem, team));
      });
    }

    // Add homework to newly selected teams
    for (const team of teams) {
      if (!checkHomeworkWithTeam(team, selectedItem)) {
        await createHomework({ ...selectedItem, teamId: team.id }).then((res) => {
          console.info(res.success);
          dispatch(addHomework(selectedItem, team));
        });
      }
    }
  
    // Handle favorites
    if (checkFavorite(selectedItem) === false && addToFavorite === true) {
      await createFavoriteJourney(selectedItem).then((res) => {
        dispatch(addFavorite(res.favorite));
      });
    }
  
    if (checkFavorite(selectedItem) === true && addToFavorite === false) {
      await deleteFavoriteBySubTitle(selectedItem).then((res) => {
        console.log(res.msg);
        dispatch(deleteFavorite(selectedItem));
      });
    }
  
    setSelectedItem(null);
  }, [checkFavorite, checkHomeworkWithTeam, selectedItem, dispatch, manageTeams]);


  const handleNotifyMember = async () => {
    setIsNotifying(true)
    await notifyHomework(currentTeam.id).then((res) => {
      setIsNotifying(false)
      setIsNotified(true)
      message.success('Notification sent successfully')

      console.log({ res })

      setTimeout(() => {
        setIsNotified(false)
      }, 5000);
    })
  }

  const handleSelectedTags = (tag) => () => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };
  

  return (
    <div className={styles.homework}>
      {homework?.children.map(category => (
        <div key={category.title} className={styles.videoCategory}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '15px',
          }}>
            <p className={styles.videoCatogoryHeader}>{category.title}</p>
            {(isManager || isPlayerManager) && (
              <button className={styles.button} disabled={isNotifying} onClick={handleNotifyMember}>
                <span className={styles.desktopText}>
                  {isNotified ? 'Notification Sent' : 'Notify Members'}
                </span>
                <img src={bellIcon} alt="Notify" className={styles.mobileIcon} />
            </button>
            )}
          </div>
          <div className={styles.videoCluster} >
            {selectedTags.length > 0 && (
              <div style={{
                display: 'flex',
                background: 'rgb(244, 251, 255)',
                width: '100%',
                padding: '1.2rem 1.5rem .8rem 1.5rem',
                borderBottom: '1px solid #bfcdd9'
              }}>
                <div style={{
                  color: '#164162',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '6px',
                }}>
                  Tag Filter: {selectedTags.map((tag, i) => <span key={`${tag}-${i}`} style={{
                    color: '#bfcdd9'
                  }}>#{tag}</span>)} <span style={{
                    color: '#bfcdd9'
                  }}>|</span>
                  <button type="button" className={styles.tagButton} style={{
                    border: 'none',
                    fontSize: 'inherit',
                    marginLeft: '0px'
                  }} onClick={() => setSelectedTags([])}><CloseOutlined style={{
                    fontSize: '12px',
                  }} /> clear</button>
                </div>
              </div>
            )}
          <ul className={styles.listFolders}>
            {category?.children?.filter((item) => {
              if (selectedTags.length === 0) {
                return true
              } else {
                return Array.isArray(item.tags) && item.tags.some(tag => selectedTags.includes(tag))
              }
            }).map((child) => {
              let link = ''

              if (child?.subFolder) {
                link = child?.subFolder + '/' + child.title + '/' + true
              } else {
                link = child.actionType + '/' + child.subTitle + '/' + true
              }

              return (
                <li key={child.title} className={styles.listRow}>
                  <div className={styles.listRow}>
                    <div className={styles.listItem}>
                      <img className={styles.pin} src={(checkHomeworkWithFavorite(child) || checkFavorite(child)) ? PinRed : PinGrey} style={{ cursor: 'pointer' }} onClick={() => handleFavoriteClick(child)} />
                      <Link
                        to={link}
                        className={styles.textListItem}
                      >
                        {child.title}
                      </Link>
                    </div>
                    {/* <div className={styles.bookmarkColumn}>
                      <img
                        className={styles.bookmark}
                        src={BookmarkGrey}
                        data-subtitle={child.subTitle}
                        onClick={(e) => switchBookmark(e)}
                      />
                    </div> */}
                  </div>
                  <div className={styles.listItemRow} style={{
                    display: 'flex', gap: '5px', flexWrap: 'wrap', paddingRight: '30px', paddingLeft: '30px'
                  }}>
                    {child?.tags && child.tags.length > 0 ? <>
                      {child.tags.map((item, i) => <button className={styles.tagButton} type="button" key={i} onClick={handleSelectedTags(item)} style={{
                        color:  selectedTags.includes(item) ? '#bfcdd9' : '#e1343f',
                        borderBottom: `1px solid ${selectedTags.includes(item)  ? '#bfcdd9' : '#e1343f'}`

                      }}>#{item}{child.tags.length - 1 === i ? '' : ','}</button>)}
                    </> : null}
                    {isManager && <button className={styles.tagButton} type="button" onClick={() => setSelectedHomework(child)}>+ Tags</button>}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      ))}
      {selectedItem && (
        <ModalAssignment
          manageTeams={manageTeams}
          onClose={() => setSelectedItem(null)}
          initFav={checkFavorite(selectedItem)}
          onSubmit={onSubmit}
          selectedItem={selectedItem}
        />
      )}
      {selectedHomework && (
        <ModalHomeworkTag onClose={() => setSelectedHomework(null)} homeworkId={selectedHomework.id} />
      )}
    </div>
  )
}

export default Homework